import React, { useContext, useState } from "react";
import { Context } from "../../../store";
import DashboardTreasureTile from "../DashboardTreasureTile";
import useWindowDimensions from "../../../hooks/useWidowDimensions";
import DashboardNotWhitelisted from "../DashboardNotWhitelisted";
import HomeMint from "../../HomePage/HomeMint";
import MarketplaceButton from "../../common/MarketplaceButton";
import TimedMarketplaceButton from "../../common/MarketplaceButton/TimedMarketplaceButton";
import { BASE_REWARD, MAX_LEVEL } from "../../../constant/blockchain";
import Tooltip from "../../common/Tooltip";
import DashboardKeyRewards from "../DashboardKeyRewards";
import { formatterUS } from "../../../utils";
import leftArrowIcon from "assets/images/singleNFTPage/arrow_left_light.svg";
import {useTokenContext} from "../../../contexts/tokenContext";
import {useAppContext} from "../../../contexts/appContext";
import {useStakingContext} from "../../../contexts/stakingContext";
import {useWeb3ModalAccount} from "@web3modal/ethers/react";

const DashboardGameTab = ({
  probToOpen,
  onView,
  amountUpgradeKey,
  handleUpgradeModal,
  handleTreasureModal,
  handleTreasureWarningModal,
  amountOpenChest,
  onOpenDeactivateKeyModal,
  onOpenActivateKeyModal,
  onClaimBase,
  onClaimAllBase,
  onViewTokenPage,
  isAnimated,
  onClose,
  tresrUpgradeCost
}) => {
  const [{ loader }] = useContext(Context);
  const { balances } = useAppContext();
  const { isConnected } = useWeb3ModalAccount();
  const { selectedToken, tokenList } = useTokenContext()
  const { stakingBalances, restrictedStaking } = useStakingContext();
  const [{ theme }] = useContext(Context);

  const isDark = theme === "dark";
  const windowParams = useWindowDimensions();

  const isMobile = windowParams?.width <= 640;
  const isActive = !!selectedToken?.staked;
  const lineWidth = (selectedToken?.level / MAX_LEVEL) * 100;
  const lineWidthPer = `${lineWidth}%`;
  const isInsufficientBalance = balances.balanceSmrtr < amountUpgradeKey && balances.balanceTresr < tresrUpgradeCost;

  const dashboardMintContent = () => {
    if (!isConnected) return <HomeMint />;
    if (tokenList.length === 0) {
      return <DashboardNotWhitelisted showStaking={!restrictedStaking}/>;
    }
    return <HomeMint />;
  };

  return (
    <>
      {!selectedToken ? (
        dashboardMintContent()
      ) : (
        <div
          className={`dashboard__data__content--form__selectedToken ${
            isAnimated
              ? "nft__image--placeholder dashboard__data__content--form__selectedToken--isAnimated"
              : ""
          }`}
        >
          {isMobile && (
            <div
              className="absolute left-0 cursor-pointer top-0 text-[#BBC5FF] flex font-semibold"
              onClick={() => onClose()}
            >
              <img alt="back button"
                src={leftArrowIcon}
                style={{ minWidth: "20px", minHeight: "20px", width: "20px" }}
              />
              &nbsp;&nbsp;Back To All Keys
            </div>
          )}
          <div
            className="dashboard__data__content--form__selectedToken--wrapper"
            onClick={onView}
          >
            <img src={selectedToken?.image} alt={`Level ${selectedToken.level} token`}
            />
            <div className="dashboard__data__content--form__selectedToken--keyTier">
              Level {selectedToken?.level}
            </div>
          </div>

          {!isAnimated && (
            <div className="dashboard__data__content--form__selectedToken--info">
              <div
                className="dashboard__data__content--form__selectedToken--info__title"
                onClick={onViewTokenPage}
              >
                {selectedToken?.name} #{selectedToken?.tokenId}
              </div>
              <Tooltip
                tooltipText={
                  "Not all Treasure Boxes will open. You can grow your probability of opening to 90% by staking TRESR in the Bonus Pool for an extended period of time."
                }
                top={30}
              >
                <div className="dashboard__data__content--form__selectedToken--info__percentToOpen">
                  <div className="flex">
                    {!!selectedToken?.staked ? (
                      <div className="-mt-1">
                        {probToOpen?.toFixed(1)} % chance to open the box
                      </div>
                    ) : (
                      <div className="-mt-1">
                        You should activate the key to open the box
                      </div>
                    )}
                  </div>
                </div>
              </Tooltip>
              <div className="dashboard__data__content--form__selectedToken--info__tresrPerDay--container">
                <Tooltip tooltipText={"Est for All Keys"} top={30} isBottom>
                  <div className="dashboard__data__content--form__selectedToken--info__tresrPerDay">
                    <div className="text-white">
                      Total Key Rewards:&nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                    {!loader?.componentActive && (
                      <>
                        {formatterUS(stakingBalances.balanceTotalTresrRewardsPerSec *
                                24 *
                                3600 *
                                30
                        )}
                      </>
                    )}{" "}
                    TRESR/MONTH
                  </div>
                </Tooltip>
              </div>
              {+selectedToken?.level < 150 &&
                <>
                  <div className="dashboard__data__content--form__selectedToken--info__countToNext dashboard__data__content--form__selectedToken--info__countToNext--margin">
                    <p className="dashboard__data__content--form__selectedToken--info__countToNext--greyText">
                      Upgrade cost
                    </p>
                    <div className="dashboard__data__content--form__selectedToken--info__countToNext--blackText">
                      <div className="flex">
                        {selectedToken?.zone !== 6 &&
                        <div className="-mt-1">
                          {formatterUS(amountUpgradeKey, 0)} SMRTR OR&nbsp;
                        </div>
                        }
                        {
                          <div className="-mt-1">
                            {formatterUS(tresrUpgradeCost, 2)} TRESR
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="dashboard__data__content--form__selectedToken--info__countToNext">
                    <p className="dashboard__data__content--form__selectedToken--info__countToNext--greyText">
                      Next Key Level Est. Monthly
                    </p>
                    <div className="dashboard__data__content--form__selectedToken--info__countToNext--blackText">
                      <div className="flex">
                        <div className="-mt-1">
                          {(+selectedToken?.level + 1) *
                            +selectedToken?.tierTresr *
                            BASE_REWARD *
                            30}{" "}
                          TRESR
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
              {!isMobile && (
                <div className="dashboard__data__content--form__selectedToken--info__progressWrapper">
                  <div className="dashboard__data__content--form__selectedToken--info__progressWrapper__bar">
                    <div
                      className={`dashboard__data__content--form__selectedToken--info__progressWrapper__line`}
                      style={{ width: lineWidthPer }}
                    />
                  </div>

                  <div className="dashboard__data__content--form__selectedToken--info__progressWrapper__info">
                    Key level: {selectedToken?.level}/{MAX_LEVEL}
                  </div>
                </div>
              )}
              {!isMobile && (
                <div className="dashboard__data__content--form__selectedToken--info__buttonWrap">
                  <TimedMarketplaceButton
                    onClick={
                      isActive
                        ? handleUpgradeModal.open
                        : handleTreasureWarningModal.open
                    }
                    isInsufficientBalance={isInsufficientBalance}
                    targetUpgradeDate={selectedToken?.upgradeToDelay}
                    disabled={selectedToken?.level >= 150}
                    burnSmrtr={selectedToken?.zone !== 6}
                    isBlue
                    currentLevel={selectedToken?.level}
                  />
                  {selectedToken?.staked ? (
                    <MarketplaceButton
                      title={"Deactivate"}
                      isNormal
                      onClick={onOpenDeactivateKeyModal}
                    />
                  ) : (
                    <MarketplaceButton
                      title={"Activate"}
                      isNormal
                      onClick={onOpenActivateKeyModal}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}

      {isMobile && (
        <div className="dashboard__data__content--form__selectedToken--info__progressWrapper">
          <div className="dashboard__data__content--form__selectedToken--info__progressWrapper__bar">
            <div
              className={`dashboard__data__content--form__selectedToken--info__progressWrapper__line`}
              style={{ width: lineWidthPer }}
            />
          </div>

          <div className="dashboard__data__content--form__selectedToken--info__progressWrapper__info">
            Key level: {selectedToken?.level}/{MAX_LEVEL}
          </div>
        </div>
      )}

      {isMobile && (
        <div className="dashboard__data__content--form__selectedToken--info__buttonWrap">
          <TimedMarketplaceButton
            onClick={
              isActive
                ? handleUpgradeModal.open
                : handleTreasureWarningModal.open
            }
            isInsufficientBalance={isInsufficientBalance}
            targetUpgradeDate={selectedToken?.upgradeToDelay}
            disabled={selectedToken?.level >= 150}
            isBlue
            currentLevel={selectedToken?.level}
            burnSmrtr={selectedToken?.zone !== 6}
          />

          {selectedToken?.staked ? (
            <MarketplaceButton
              title={"Deactivate"}
              onClick={onOpenDeactivateKeyModal}
            />
          ) : (
            <MarketplaceButton
              title={"Activate"}
              onClick={onOpenActivateKeyModal}
            />
          )}
        </div>
      )}

      <DashboardTreasureTile
        isAnimated={isAnimated}
        onOpenModal={handleTreasureModal.open}
        onOpenWarningModal={handleTreasureWarningModal.open}
        amountOpenChest={amountOpenChest}
        isDark={isDark}
      />

      {!!(selectedToken?.tokenId && balances.poolAllocation) && (
        <DashboardKeyRewards
          onClaimBase={onClaimBase}
          onClaimAllBase={onClaimAllBase}
          isAnimated={isAnimated}
          isDark={isDark}
          isMobile={isMobile}
        />
      )}
    </>
  );
};
export default DashboardGameTab;
