import React, {createContext, useState, useContext, useEffect, useCallback} from 'react';
import {useAppContext} from "./appContext";
import useHandleContracts from "../hooks/blockchain/useHandleContracts";
import {hexToNumber} from "../utils/blockchain";
import useHandleNFT from "../hooks/blockchain/useHandleNFT";
import {Context} from "../store";
import {ACTIVATE_KEY_ALERT, ALERT_STATUS_FAILURE, ALERT_STATUS_SUCCESS, UPGRADE_KEY_ALERT} from "../constant/alert";
import PubSub from "pubsub-js";
import {PubSubEvents} from "../constant/events";
import useHandleToastAlert from "../hooks/alert/useHandleToastAlert";
import useHandleLoader from "../hooks/loader/useHandleLoader";
import {
  calcKeyUpgradeDelay,
  calcRewards,
  getImagePath
} from "../constant/blockchain";
import axios from "axios";
import {useWeb3ModalAccount} from "@web3modal/ethers/react";
import { roundStaking } from './utils';

const TokenContext = createContext({
  tokenList: [],
  stakedTokenList: [],
  selectedToken: null,
  setTokenList: (list) => {},
  setSelectedToken: (selected) => {},
  updateTokens: (tokenIds) => {},
  upgradeKey: () => {},
  unlockTreasure: () => {},
  refreshTokenList: () => {},
  bulkStake: () => {},
  bulkUnlockBoxes: () => {},
  hasUnclaimedTresr: false,
  prizeSeasonName: undefined,
  prizeSeasonRoot: undefined,
  refreshPrizeInfo: () => {},
  prizeSeasonBoosts: [],
  getBulkUnlockFee: () => {},
});

export const useTokenContext = () => useContext(TokenContext);
const tokenListState = {
  loading: false
};

export const TokenContextProvider = ({ children }) => {
  const [_, ACTION] = useContext(Context);
  const { address, isConnected } = useWeb3ModalAccount();
  const { refreshBalances, balances } = useAppContext();
  const [tokenList, setTokenList] = useState([]);
  const [stakedTokenList, setStakedTokenList] = useState([]);
  const [selectedToken, internalSetSelectedToken] = useState(null);
  const [hasUnclaimedTresr, setHasUnclaimedTresr] = useState(false);
  const { success, error } = useHandleToastAlert();
  const handleToastAlert = useHandleToastAlert();
  const { loaderWrapper } = useHandleLoader();
  const [prizeSeasonName, setPrizeSeasonName] = useState();
  const [prizeSeasonRoot, setPrizeSeasonRoot] = useState();
  const [prizeSeasonBoosts, setPrizeSeasonBoosts] = useState([]);
  const [prizeSeasonBoostInfo, setPrizeSeasonBoostInfo] = useState();

  const handleNFT = useHandleNFT();
  const {
    contractNFKeyWithSigner,
    contractNFKeyStakingWithSigner,
    contractTresrCoinWithSigner,
    contractPrizeSeason,
    contractsLoaded,
  } = useHandleContracts();

  const upgradeKey = useCallback(async (useSmarter) => {
    if (!selectedToken) {
      throw new Error(`No token is selected`);
    }

    const cost = await contractNFKeyWithSigner.getUpgradeKeyCost(selectedToken.tokenId, useSmarter)
    const approved = await contractTresrCoinWithSigner.allowance(address, contractNFKeyWithSigner.target)

    console.log("CALLING UPGRADE", cost, approved, useSmarter)


    const gas = await contractNFKeyWithSigner.upgradeKey.estimateGas(selectedToken.tokenId, useSmarter);
    return contractNFKeyWithSigner
      .upgradeKey(selectedToken.tokenId, useSmarter, { gasLimit: gas * BigInt(130) / BigInt(100) })
      .then(async (tx) => {
        await tx.wait();
        internalSetSelectedToken({
          ...selectedToken,
          upgradeInProgress: true,
          level: selectedToken.level + 1,
          rewardsPerSecond: calcRewards(selectedToken.level + 1, selectedToken.tierTresr),
          image: getImagePath(selectedToken.level + 1),
          keyUpgradeDelay: calcKeyUpgradeDelay(selectedToken.level + 1)
        });

        PubSub.publish(PubSubEvents.KEY_UPGRADED.event, PubSubEvents.KEY_UPGRADED.args(selectedToken.tokenId));

        ACTION.SET_TRANSANCTION_HASH(tx?.hash);
        return true;
      })
      .catch((err) => {
        ACTION.SET_ALERT(
          true,
          ALERT_STATUS_FAILURE,
          UPGRADE_KEY_ALERT(selectedToken?.tokenId, false)
        );
        throw err;
      });
  }, [selectedToken, ACTION, contractNFKeyWithSigner, contractTresrCoinWithSigner, address]);

  const bulkStake = useCallback(async (tokenList) => {
    const maxBatchSize = 50;
    const numBatches = Math.ceil(tokenList.length / maxBatchSize);
    for (let i = 0; i < numBatches; i++) {
      const start = i * maxBatchSize;
      const batchIds = tokenList.slice(start, start + maxBatchSize);
      const gas = await contractNFKeyStakingWithSigner.bulkStake.estimateGas(batchIds);
      await contractNFKeyStakingWithSigner
        .bulkStake(batchIds, {gasLimit: gas * BigInt(130) / BigInt(100)})
        .then(async (tx) => {
          await tx.wait();
          ACTION.SET_TRANSANCTION_HASH(tx?.hash);
        })
        .catch((err) => {
          console.log(err);
          ACTION.SET_ALERT(
            true,
            ALERT_STATUS_FAILURE,
            ACTIVATE_KEY_ALERT(selectedToken?.tokenId, false)
          );
        });
    }
    setTokenList(tl => {
      const newList = [...tl];
      for (const tokenItem of tokenList) {
        const token = newList.find(item => item.tokenId === tokenItem);
        token.staked = true;
      }
      return newList;
    });
    success('You have successfully activated the keys')
  }, [selectedToken, contractNFKeyStakingWithSigner]);


  const unlockTreasure = useCallback(async () => {
    if (!selectedToken) {
      return;
    }

    const unlockFee = await contractNFKeyStakingWithSigner
      .getOpenChestFee(selectedToken.tokenId);

    if (unlockFee > balances.balanceAvax * 10 ** 18) {
      handleToastAlert.error("Insufficient balance");
      return;
    }

    const tresrBurnAmount = await contractNFKeyStakingWithSigner
      .calcUnlockCost(selectedToken.tokenId)

    const allowance = await contractTresrCoinWithSigner
      .allowance(address, process.env.REACT_APP_NFKEY_STAKING_ADDRESS)

    if (allowance < tresrBurnAmount) {
      const approveTx = await contractTresrCoinWithSigner
        .approve(process.env.REACT_APP_NFKEY_STAKING_ADDRESS, tresrBurnAmount);
      await approveTx.wait();
    }

    try {
      const gas = await contractNFKeyStakingWithSigner.openChest.estimateGas(selectedToken.tokenId, {
        value: unlockFee,
      });
      const tx = await contractNFKeyStakingWithSigner
        .openChest(selectedToken.tokenId, {
          value: unlockFee,
          gasLimit: gas * BigInt(130) / BigInt(100)
        });
      const transaction = await tx.wait();

      ACTION.SET_TRANSANCTION_HASH(tx?.hash);
    } catch (err) {
      handleToastAlert.error("Network error opening chest. Please try again.")
    }
  }, [
    selectedToken,
    balances,
    address,
    handleToastAlert,
    contractNFKeyStakingWithSigner,
    contractTresrCoinWithSigner,

  ]);

  const getBulkUnlockFee = useCallback(async (tokenIds) => {
    const maxBatchSize = 50;
    const numBatches = Math.ceil(tokenIds.length / maxBatchSize);
    let total = 0;
    for (let i = 0; i < numBatches; i++) {
      const start = i * maxBatchSize;
      const batchIds = tokenIds.slice(start, start + maxBatchSize);
      const raw = await contractNFKeyStakingWithSigner
        .getOpenChestBatchFee(batchIds.length);
      total += Number(raw) /1e18;
    }
    return total;
  }, [contractNFKeyStakingWithSigner]);

  const bulkUnlockBoxes = useCallback(async (tokenIds, tresrToPledge) => {
    const maxBatchSize = 500;
    const numBatches = Math.ceil(tokenIds.length / maxBatchSize);
    for (let i = 0; i < numBatches; i++) {
      const start = i * maxBatchSize;
      const batchIds = tokenIds.slice(start, start + maxBatchSize);

      const unlockFee = await contractNFKeyStakingWithSigner
        .getOpenChestBatchFee(batchIds.length);

      if (unlockFee > balances.balanceAvax * 10 ** 18) {
        handleToastAlert.error("Insufficient balance");
        return;
      }

      try {
        const bigAmount = roundStaking(tresrToPledge);
        const gas = await contractNFKeyStakingWithSigner.openChestsOffchain.estimateGas(address, batchIds, bigAmount, {
          value: unlockFee,
        });
        const tx = await contractNFKeyStakingWithSigner
          .openChestsOffchain(address, batchIds, bigAmount, {
            value: unlockFee,
            gasLimit: gas * BigInt(130) / BigInt(100)
          });
        const transaction = await tx.wait()

        ACTION.SET_TRANSANCTION_HASH(tx?.hash);
      } catch (err) {
        console.error(err)
        throw err;
      }
    }
  }, [balances, contractNFKeyStakingWithSigner, address]);


  const setSelectedToken = useCallback(async (token) => {
    if (selectedToken === token) {
      return;
    }

    // TODO REMOVE REDUX
    ACTION.SET_COMPONENT_LOADER(false);

    internalSetSelectedToken(token);
  }, [selectedToken, ACTION]);

  const updateTokens = useCallback(async (tokenIds) => {
    if (!address || !handleNFT) {
      return
    }

    const tokens = await handleNFT.getDetailsForTokens(tokenIds);
    setTokenList((list) => {
      for (let i = 0; i < tokens.length; i++) {
        const tokenId = tokens[i].tokenId;
        const index = list.findIndex(i => i.tokenId === tokenId);
        if (index < 0) {
          continue;
        }
        list = [...list.slice(0, index), tokens[i], ...list.slice(index + 1)];
      }
      return list;
    });

    // This is very subtle. Let's say that the user upgrades a key/opens a chest, triggering an update
    // The getNFTDetails can take a while, so the user may have selected a different key by that point.
    // If they haven't selected a different key then we need to update the current with the new data
    // but don't do anything if they have selected a different one.
    internalSetSelectedToken(realCurrent => {
      const foundToken = tokens.find((t) => t.tokenId === realCurrent?.tokenId);
      return foundToken ?? realCurrent;
    });
  }, [handleNFT, address]);


  useEffect(() => {
    const opened = PubSub.subscribe(PubSubEvents.CHESTS_OPENED.event, (_, { successes, keyIds }) => {
      const keysToUpdate = [];
      for (let i = 0; i < keyIds.length; i++) {
        if (successes[i]) {
          keysToUpdate.push(keyIds[i]);
        }
      }
      updateTokens(keysToUpdate);
    });

    return () => {
      PubSub.unsubscribe(opened);
    };
  }, [updateTokens]);

  useEffect(() => {
    const ownTokenList = tokenList
      ?.filter((item) => item?.owner === address)
      ?.filter((item) => item?.staked === true);

    setStakedTokenList(ownTokenList);

  }, [tokenList, address]);

  const refreshTokenList = useCallback( async() => {
    if (!address || !contractsLoaded || tokenListState.loading) {
      return;
    }
    tokenListState.loading = true;
    try {
      const tokenList = await handleNFT.getTokenList(address);
      if (!tokenList?.length) {
        setTokenList([]);
      } else {
        setTokenList(tokenList);
      }
    } finally {
      tokenListState.loading = false;
    }
    // })
  }, [address, contractsLoaded]);

  useEffect(() => {
    refreshTokenList();
  }, [refreshTokenList]);



  const refreshUnclaimedTresr = useCallback(async () => {
    if (tokenList.length === 0) {
      setHasUnclaimedTresr(false);
    }

    const canClaimTresrs = await Promise.all(tokenList.map(token =>
      contractNFKeyWithSigner._canClaimTresr(token.tokenId)
    ));

    let canClaim = false;
    for (const claim of canClaimTresrs) {
      canClaim = canClaim || claim;
    }
    setHasUnclaimedTresr(canClaim);

  }, [tokenList, contractNFKeyWithSigner]);

  useEffect(() => {
    if (!contractsLoaded) {
      return;
    }
    refreshUnclaimedTresr();
  }, [refreshUnclaimedTresr, contractsLoaded]);


  const claimTresr = useCallback(async () => {
    const tresrMap = await Promise.all(tokenList.map(token =>
      contractNFKeyWithSigner._canClaimTresr(token.tokenId).then((canClaim) =>({
        token,
        canClaim
      }))
    ));
    const shouldClaim = tresrMap.filter(t => t.canClaim).map(t => t.token.tokenId);
    if (shouldClaim.length === 0) {
      return;
    }

    const tx = await contractNFKeyWithSigner.claimTresr(shouldClaim);
    await tx.wait();
    setHasUnclaimedTresr(false);
  }, [tokenList, contractNFKeyWithSigner]);

  const updateBoostPercentages = useCallback(async () => {
    if (!contractPrizeSeason || !address || !prizeSeasonBoostInfo) {
      return;
    }

    const boosts = await contractPrizeSeason.getBoosts();
    for (let i = 0; i < boosts.length; i++) {
      const boostValue = await contractPrizeSeason.calcRelativeBoosts(address, i);
      const userVals = boostValue[0].map(v => Number(v));
      const maxVals = boostValue[1].map(v => Number(v));

      for (let j = 0; j < userVals.length; j++) {
        const relative = userVals[j] / maxVals[j];
        const perc = Math.min(relative * 100, 100);
        prizeSeasonBoostInfo[i].boosts[j].perc = perc;
        prizeSeasonBoostInfo[i].boosts[j].weight = prizeSeasonBoostInfo[i].boosts[j].weight/10;
      }
    }

    setPrizeSeasonBoosts(prizeSeasonBoostInfo);
  }, [prizeSeasonBoostInfo, contractPrizeSeason, address]);

  useEffect(() => {
    if (!contractsLoaded) {
      return;
    }

    updateBoostPercentages();
  }, [updateBoostPercentages, contractsLoaded]);

  const refreshPrizeInfo = useCallback(async () => {
    if (!contractPrizeSeason || !address) {
      return;
    }

    const name = await contractPrizeSeason.name();
    setPrizeSeasonName(name);
    setPrizeSeasonRoot(`${process.env.REACT_APP_IPFS_ROOT}prize-seasons/${name}/`);
    const {data: boostInfo} = await axios.get(`${process.env.REACT_APP_IPFS_ROOT}prize-seasons/${name}/info/boosts.json`);
    setPrizeSeasonBoostInfo(boostInfo);
  }, [contractPrizeSeason, address]);

  useEffect(() => {
    if (!contractsLoaded) {
      return;
    }

    refreshPrizeInfo();
  }, [contractsLoaded, refreshPrizeInfo])

  useEffect(() => {
    const logoutToken = PubSub.subscribe(PubSubEvents.LOGOUT, () => {
      setTokenList([]);
      internalSetSelectedToken(null);
    });


    const keysChanged = PubSub.subscribe(PubSubEvents.NFKEYS_CHANGED.event, (a, {keyIds}) => {
      updateTokens(keyIds);
      PubSub.publish(PubSubEvents.BALANCE_CHANGED);
    });

    const refresh = PubSub.subscribe(PubSubEvents.NFKEYS_BATCH_MINT, () => {
      refreshTokenList()
    });


    return () => {
      PubSub.unsubscribe(logoutToken);
      PubSub.unsubscribe(keysChanged);
      PubSub.unsubscribe(refresh);
    };
  }, [refreshTokenList, updateTokens]);

  const value = {
    tokenList,
    setTokenList,
    selectedToken,
    setSelectedToken,
    updateTokens,
    upgradeKey,
    stakedTokenList,
    unlockTreasure,
    refreshTokenList,
    bulkStake,
    hasUnclaimedTresr,
    claimTresr,
    bulkUnlockBoxes,
    prizeSeasonName,
    prizeSeasonRoot,
    prizeSeasonBoosts,
    refreshPrizeInfo,
    getBulkUnlockFee,
  };

  return (
      <TokenContext.Provider value={value}>
          {children}
      </TokenContext.Provider>
  );
};
